<template>
  <div>
    <v-row v-if="isCreate">
      <v-spacer />

      <v-btn
        class="ma-5"
        color="primary"
        outlined
        @click.stop="isCreate = !isCreate"
      >
        <v-icon left>mdi-plus</v-icon>
        {{ $t('BUTTON.ADD') }} {{ $t('COMMENTS.ITEM') }}</v-btn
      >
    </v-row>
    <v-row v-else>
      <v-col>
        <v-form ref="createCommentForm" v-model="valid">
          <add-comment
            v-model="newComment"
            class="ma-3"
            :post-id="postId"
            :errors="errors.getAll()"
            @update-prop="clearErrorByName($event)"
          >
            <template v-slot:buttons>
              <v-spacer />
              <v-btn
                outlined
                class="mr-2"
                depressed
                color="grey"
                large
                @click="cancelComment"
              >
                {{ $t('BUTTON.CANCEL') }}
              </v-btn>
              <v-btn
                depressed
                color="primary"
                :disabled="!valid"
                large
                @click="createComment"
              >
                {{ $t('BUTTON.SAVE') }}
              </v-btn>
            </template>
          </add-comment>
        </v-form>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="comments.length">
        <comments-list
          :comments="comments"
          class="ma-3"
          :errors="errors.getAll()"
          @addItem="addItem"
          @updateItem="updateItem"
          @removeItem="removeItem"
        >
        </comments-list>
        <confirm-dialog ref="confirm"></confirm-dialog>
      </v-col>
      <v-col v-else>
        <v-card class="ma-3" outlined dense disabled>
          <v-card-text class="text-center wrapper">
            <p class="title">
              {{ $t('BUTTON.ADD') }} {{ $t('COMMENTS.ITEMS') }}
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AddComment from '@/modules/comments/components/AddComment';
import CommentsList from '@/modules/comments/components/CommentsList';
import { CommentsService } from '@/services/comments.service';
import DataOptions from '@/helpers/DataOptions.js';
import errors from '@/mixins/errors';
import { debounce, unionBy } from 'lodash';
import ConfirmDialog from '@/components/ConfirmDialog';

export default {
  name: 'CommentsBoard',

  components: { CommentsList, AddComment, ConfirmDialog },

  mixins: [errors],

  props: {
    postId: {
      type: Number,
      required: true,
    },

    delay: {
      type: Number,
      default: 500,
    },
  },

  data: () => {
    return {
      error: null,
      comments: [],
      isCreate: true,
      newComment: {},
      getDataCommentsDebounced: null,
      valid: true,
      meta: {
        total: 0,
        last_page: 0,
      },
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['id'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: false,
      },
    };
  },

  computed: {
    preparedOptions() {
      return DataOptions.createFromObject(this.options).toBackendFormat();
    },
  },

  watch: {
    options: {
      handler() {
        this.getDataCommentsDebounced();
      },
      deep: true,
    },
  },

  created() {
    this.getDataCommentsDebounced = debounce(this.getComments, this.delay);
    this.getDataCommentsDebounced();
  },

  methods: {
    refreshData() {
      this.comments = [];
      if (this.options.page > 1) {
        this.options.page = 1;
      } else {
        this.getComments();
      }
    },

    addItem() {
      if (this.options.page < this.meta.last_page) {
        this.options.page++;
      }
    },

    getComments() {
      return CommentsService.getPostComments(this.postId, this.preparedOptions)
        .then(({ data: { meta, data } }) => {
          this.meta = meta;

          this.comments = unionBy(this.comments, data, 'id');
          return data;
        })
        .catch(this.handleErrors);
    },

    createComment() {
      CommentsService.create({ post_id: this.postId, ...this.newComment })
        .then(() => {
          this.refreshData();
          this.newComment = {};
          this.resetCreateForm();
        })
        .catch((response) => {
          this.handleErrorsByResponse(response);
        });
    },

    cancelComment() {
      this.newComment = {};
      this.resetCreateForm();
      this.isCreate = true;
    },

    updateItem(item) {
      this.comments.splice(
        this.comments.findIndex((el) => el.id === item.id),
        1,
        item
      );
    },

    resetCreateForm() {
      this.$refs.createCommentForm.reset();
    },

    removeItem(el, key) {
      this.$refs.confirm
        .open(this.$t('MESSAGE.REALLY_WANT_TO_REMOVE'), null, {
          cancelBtnText: this.$t('BUTTON.CANCEL'),
          agreeBtnText: this.$t('BUTTON.DELETE'),
          agreeBtnColor: 'error',
        })
        .then((confirm) => {
          if (confirm) {
            CommentsService.remove(el.id)
              .then(() => {
                this.comments.splice(key, 1);
                if (this.options.page !== this.meta.last_page) {
                  this.getDataCommentsDebounced();
                }
              })
              .catch((response) => {
                this.handleErrorsByResponse(response);
                this.refreshData();
              });
          }
        });
    },
  },
};
</script>

<style scoped>
.wrapper {
  position: relative;
  height: 200px;
}
.title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
