<template>
  <v-form ref="updateCommentForm" v-model="valid" lazy-validation>
    <v-row class="mx-auto pa-2" dense>
      <v-col cols="11">
        <comment-line v-model="form" :readonly="showMode" />
      </v-col>
      <v-col class="ma-auto" cols="1">
        <v-row dense>
          <v-col>
            <v-btn v-if="form.updatable && showMode" icon @click="edit">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-btn v-if="form.updatable" icon @click="removeItem">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="!showMode" dense>
      <v-btn outlined class="ma-2" depressed color="grey" large @click="cancel">
        {{ $t('BUTTON.CANCEL') }}
      </v-btn>
      <v-btn
        depressed
        type="submit"
        :disabled="!valid"
        large
        color="primary"
        class="ma-2"
        @click.prevent="updateItem"
      >
        {{ $t('BUTTON.UPDATE') }}
      </v-btn>

      <v-spacer />
    </v-row>
  </v-form>
</template>

<script>
import CommentLine from '@/modules/comments/components/forms/CommentLine';
import formEditable, { EDIT, SHOW } from '@/mixins/form-editable';
import { CommentsService } from '@/services/comments.service';

export default {
  name: 'EditCommentLine',

  components: { CommentLine },

  mixins: [formEditable],

  data: () => {
    return {
      mode: SHOW,
      valid: true,
    };
  },

  computed: {
    showMode() {
      return this.mode === SHOW;
    },
  },

  methods: {
    removeItem() {
      this.$emit('removeItem', this.form);
    },

    updateItem() {
      CommentsService.update(this.form.id, this.form)
        .then(({ data }) => {
          this.$emit('updateItem', data);
          this.mode = SHOW;
        })
        .catch(this.handleErrors);
    },

    edit() {
      this.mode = EDIT;
    },

    cancel() {
      this.mode = SHOW;
      this.form = this.value;
    },
  },
};
</script>

<style scoped></style>
