import { render, staticRenderFns } from "./CommentsList.vue?vue&type=template&id=d6b7a408&"
import script from "./CommentsList.vue?vue&type=script&lang=js&"
export * from "./CommentsList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VResponsive } from 'vuetify/lib/components/VResponsive';
installComponents(component, {VCard,VResponsive})


/* vuetify-loader */
import installDirectives from "!../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Intersect from 'vuetify/lib/directives/intersect'
installDirectives(component, {Intersect})
