/**
 * @typedef {object} backendOptions
 * @property {number} page - Page number
 * @property {number} per_page - Number of items per page
 * @property {string|string[]} sort_by - Sort field(s)
 * @property {string|string[]} order_by - Sort direction for sort field(s)
 */
class DataOptions {
  /**
   * Create a new Errors instance.
   * @param {number} page
   * @param {number} itemsPerPage
   * @param {string[]} sortBy
   * @param {boolean[]} sortDesc
   * @param {string[]} groupBy
   * @param {boolean[]} groupDesc
   * @param {boolean} mustSort
   * @param {boolean} multiSort
   */
  constructor(
    page = 1,
    itemsPerPage = 10,
    sortBy = [],
    sortDesc = [],
    groupBy = [],
    groupDesc = [],
    mustSort = true,
    multiSort = false
  ) {
    this._page = page;
    this._itemsPerPage = itemsPerPage;
    this._sortBy = sortBy;
    this._sortDesc = sortDesc;
    this._groupBy = groupBy;
    this._groupDesc = groupDesc;
    this._mustSort = mustSort;
    this._multiSort = multiSort;
  }

  /**
   * @returns {number}
   */
  get page() {
    return this._page;
  }

  /**
   * @returns {number}
   */
  get perPage() {
    return this._itemsPerPage;
  }

  /**
   * @returns {string|string[]}
   */
  get sortBy() {
    if (this._multiSort) {
      return this._sortBy;
    }

    return this._sortBy[this._sortBy.length - 1];
  }

  /**
   * @returns {string|string[]}
   */
  get orderBy() {
    const sortDesc = this._sortDesc.map((i) => (i ? 'desc' : 'asc'));

    if (this._multiSort) {
      return sortDesc;
    }

    return sortDesc[sortDesc.length - 1];
  }

  /**
   * @returns {backendOptions} - Formatted options for backend
   */
  toBackendFormat() {
    return {
      page: this.page,
      per_page: this.perPage,
      sort_by: this.sortBy,
      order_by: this.orderBy,
    };
  }

  /**
   * Create new instance from object
   *
   * @returns {DataOptions}
   */
  static createFromObject({
    page,
    itemsPerPage,
    sortBy,
    sortDesc,
    groupBy,
    groupDesc,
    mustSort,
    multiSort,
  }) {
    return new DataOptions(
      page,
      itemsPerPage,
      sortBy,
      sortDesc,
      groupBy,
      groupDesc,
      mustSort,
      multiSort
    );
  }
}

export { DataOptions as default };
