import { BaseService } from './base.service';
import { ErrorWrapper, ResponseWrapper } from './util';

export class CommentsService extends BaseService {
  static get entity() {
    return 'posts/comments';
  }

  static async getPostComments(postId, parameters = {}) {
    const params = { ...parameters };

    try {
      const response = await this.request({
        auth: true,
      }).get(`/posts/${postId}/comments`, { params });
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}
