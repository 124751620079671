<template>
  <v-card outlined dense>
    <v-card-text class="pb-0">
      <v-row>
        <v-col cols="3">
          <v-autocomplete
            v-model="form.company_id"
            :loading="!!!authors.length"
            :item-text="(item) => item.name"
            :error-messages="errors.company_id"
            :rules="rules.author"
            :label="`${$t('ITEM.AUTHOR')} *`"
            item-value="id"
            :items="authors"
            append-outer-icon="mdi-close"
            @change="updateValue('company_id', $event)"
            @click:append-outer="form.company_id = null"
          ></v-autocomplete>
        </v-col>

        <v-col cols="9">
          <v-textarea
            v-model="form.message"
            rows="2"
            :rules="rules.message"
            :error-messages="errors.message"
            counter="120"
            outlined
            @input="updateValue('message', $event)"
          ></v-textarea>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions class="pt-0">
      <slot name="buttons" />
    </v-card-actions>
  </v-card>
</template>

<script>
import i18nRefreshData from '@/mixins/i18nRefreshData';
import { maxLength, notEmpty, requiredSelectNotArray } from '@/rules';
import formEditable from '@/mixins/form-editable';
import { PostsService } from '@/services/posts.service';

export default {
  name: 'AddComment',

  filters: {},

  components: {},

  mixins: [formEditable, i18nRefreshData],

  props: {
    postId: {
      type: Number,
      required: true,
    },
  },

  data: () => {
    return {
      form: {},
      rules: {
        message: [notEmpty, maxLength(120)],
        author: requiredSelectNotArray,
      },
      authors: [],
      valid: false,
    };
  },

  mounted() {
    this.getAuthors();
  },

  methods: {
    addComment() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.$emit('createComment', { ...this.form });
    },
    getAuthors() {
      PostsService.getAuthorCompanies()
        .then(({ data }) => {
          this.authors = data;
          if (this.authors.length === 1) {
            this.form.company_id = this.authors.find(() => true)['id'];
          }
        })
        .catch((error) => {
          this.error = error;
        });
    },
  },
};
</script>
