<template>
  <v-card dense outlined>
    <v-responsive class="overflow-y-auto" max-height="400">
      <v-responsive class="d-flex align-center text-center pa-2">
        <v-card v-for="(el, key) in comments" :key="key" outlined class="ma-2">
          <edit-comment-line
            :value="comments[key]"
            @removeItem="removeItem(el, key)"
            @updateItem="updateItem"
          >
          </edit-comment-line>
        </v-card>
        <v-card v-intersect="infiniteScrolling"></v-card>
      </v-responsive>
    </v-responsive>
  </v-card>
</template>

<script>
import { dateFormat } from '@/filters';

import EditCommentLine from '@/modules/comments/components/EditCommentLine';

export default {
  name: 'CommentsList',

  components: { EditCommentLine },

  filters: {
    dateFormat,
  },

  props: {
    comments: {
      type: Array,
      required: true,
    },
  },

  data: () => {
    return {
      form: {},
    };
  },

  computed: {},

  created() {},

  methods: {
    removeItem(el, key) {
      this.$emit('removeItem', el, key);
    },

    updateItem(item) {
      this.$emit('updateItem', item);
    },

    addItem() {
      this.$emit('addItem');
    },

    infiniteScrolling() {
      this.addItem();
    },
  },
};
</script>
