<template>
  <v-row dense>
    <v-col class="my-auto" cols="1" xl="1" lg="2" sm="6">
      <v-avatar size="60">
        <v-img
          v-if="showImg"
          :src="(form.author || {}).url_avatar"
          contain
          @error="changeShowImg"
        />
        <v-icon v-else x-large color="grey darken-2">mdi-account-circle</v-icon>
      </v-avatar>
    </v-col>
    <v-col class="my-auto" cols="2" xl="2" lg="2" sd="6">
      <p class="mb-0">{{ (form.author || {}).name }}</p>
      <p class="mb-0">{{ form.created_at | dateFormat }}</p>
    </v-col>
    <v-col class="my-auto" cols="9" xl="9" lg="8" sm="12">
      <v-textarea
        v-model="form.message"
        rows="2"
        :rules="rules.message"
        :readonly="readonly"
        :error-messages="errors.message"
        outlined
        counter="120"
        @input="updateValue('message', $event)"
      ></v-textarea>
    </v-col>
  </v-row>
</template>

<script>
import formEditable from '@/mixins/form-editable';
import { maxLength, notEmpty, requiredSelectNotArray } from '@/rules';
import { dateFormat } from '@/filters';

export default {
  name: 'CommentLine',

  filters: {
    dateFormat,
  },

  mixins: [formEditable],

  data: () => {
    return {
      form: {},
      rules: {
        message: [notEmpty, maxLength(120)],
        author: requiredSelectNotArray,
      },
      authors: [],
      valid: false,
      showImg: true,
    };
  },

  methods: {
    changeShowImg() {
      this.showImg = !this.showImg;
    },
  },
};
</script>

<style scoped></style>
